import React from 'react';
import Container from '../components/container';
import SEO from '../components/seo';
import Layout from '../containers/layout';
import logo from '../assets/images/logo.png';

import {responsiveTitle1} from '../components/typography.module.css';

export default function HistoryPage() {
  return (
    <Layout>
      <SEO title="History" />
      <Container>
        <h1 className={responsiveTitle1}>History</h1>
        <img src={logo} alt="Sketch of St. Demetrius Church" />
        <div>
          <p>
            Our history indicates that the home of the Serbian people of
            Barberton and Akron was the Serbian Club in Barberton which was
            constructed in 1912. The people used the Barberton Club and the
            Russian Church (South Akron) during this time but wanted a place of
            their own.
          </p>
          <p>
            The property for the Serbian Club on Ira Avenue was purchased in
            1918 and construction was completed in the Spring of 1919.
          </p>
          <p>
            Religious life was officially recognized with the issuance of The
            Articles of Incorporation of the Serbian Orthodox Church of Akron by
            the Secretary of the Sate of Ohio on July 12, 1919 and created the
            basic foundation for the future development and growth of that life.
          </p>
          <p>
            In May 1930, an assembly was called and approved the proposal to
            build a Serbian Church. When the fund reached $5,000 construction
            would begin. In 1931 a special assembly was called to elect a
            building committee. It was an economic time known as “The Great
            Depression” but the people refused to abandon their plans and the
            first dirt of the foundation was turned over in February 1932.
          </p>
          <p>
            In spite of difficulties, the building was completed in the Fall of
            1933 and consecrated on November 12, 1933.
          </p>
          <p>
            After many years of the church on Lake Street, and the surrounding
            area neighborhoods declined. The search for a new property resulted
            in acquiring the location on Ridgewood Road in Copley Township in
            November 1978.
          </p>
          <p>
            Economic factors were becoming crucially important in the
            functioning of the Serbian Hall on Ira Avenue. The membership
            elected to sell the hall and celebrated the last Slava in November
            1980.
          </p>
          <p>
            The property was paid in full in 1982 and in August of 1982 a
            special meeting was called with the purpose of selecting a Building
            Committee. The committee recommended that the church on Lake Street
            be listed for sale in February 1983. In May of 1983 a Ground
            Blessing was held and in June 1983 a loan was approved. Construction
            began, and the complex was consecrated on May 19-20, 1984 with a
            grand celebration. On June 2, 1991, a mortgage burning celebration
            was held having paid off the loan much earlier than anticipated due
            to the hard work of members and friends.
          </p>
          <p>
            In the late summer of 1994 the membership approved the formation of
            a Building Committee to investigate the possibilities of building a
            proper church and on December 11, 1994 the membership approved to go
            forward with the new building project. In August 1995 a contract was
            signed, and a ground breaking took place in the fall of that year.
          </p>
          <p>
            On March 13, 1996 a ‘Crowning Achievement” happened when more than
            100 of our church parishioners, children, choir, and friends along
            with our parish priest and 3 other local Orthodox priests blessed
            and witnessed the installation of the dome and cross on top of our
            main cupola.
          </p>
          <p>
            In July of 1996, our iconostasis was disassembled and moved from the
            small hall chapel into the new church. This was done by our member
            Bud Gligor along with some volunteers. This job was very familiar to
            him as he had personally rebuilt it after a fire on Lake Street in
            1975. He had reconstructed it when it was moved from Lake Street to
            our Chapel in Copley and supervised the entire move into the new
            church. Our first Divine Liturgy was just after this.
          </p>
          <p>
            August 1-3, 1997 was the culmination of nearly 20 years of hard work
            and determination as we celebrated the consecration of our new
            church.
          </p>
        </div>
      </Container>
    </Layout>
  );
}
